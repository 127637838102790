.skeleton-card {
  background: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.skeleton-image {
  width: 100%;
  height: 200px;
  background: linear-gradient(90deg, #e0e0e0, #f0f0f0, #e0e0e0);
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite;
  border-radius: 8px;
  margin-bottom: 16px;
}

.skeleton-title,
.skeleton-text {
  width: 100%;
  height: 20px;
  background: linear-gradient(90deg, #e0e0e0, #f0f0f0, #e0e0e0);
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite;
  border-radius: 4px;
  margin-bottom: 12px;
}

.skeleton-title {
  height: 24px;
  width: 60%;
}

.skeleton-text {
  width: 90%;
  height: 16px;
}

@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}